<template>
    <div
        class="min-h-screen flex flex-col sm:justify-center items-center pt-6 sm:pt-0 bg-vampire-black-900 sm:bg-gray-100"
    >
        <div
            class="w-full sm:max-w-md mt-6 px-6 py-4 bg-vampire-black-900 shadow-md overflow-hidden sm:rounded-lg"
        >
            <div class="flex flex-col sm:justify-center items-center mb-10">
                <slot name="logo" />
            </div>

            <div class="mb-5 text-white">
                <slot name="head" />
            </div>

            <slot />
        </div>
    </div>
</template>
